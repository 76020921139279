import * as React from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { useMediaQuery } from 'usehooks-ts';

import Logo from '../assets/images/logo-small.svg';

// Import styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './PDFViewer.css';

export const PDFViewer = ({ url }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const screen479 = useMediaQuery('(max-width: 479px)');

  const appendPdfConfigToUrl = url => {
    return `${url}#toolbar=0&navpanes=0&zoom=${screen479 ? '35' : '90'}`;
  }

  const renderError = () => (
    <iframe
      src={appendPdfConfigToUrl(url)}
      frameBorder="0"
      scrolling="auto"
      height={screen479 ? '500px' : '1050px'}
      width={screen479 ? '330px' : '820px'}
      className="document-preview"
    ></iframe>
  )


  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.js">
      <div
        style={{
          height: screen479 ? '400px' : '1200px',
          width: screen479 ? '300px' : '900px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Viewer
          fileUrl={url}
          renderPage={renderPage}
          httpHeaders={{
            "AllowedHeaders": ["*"],
            "AllowedMethods": ["GET", "POST"],
            "AllowedOrigins": ["*"],
          }}
          plugins={[
            pageNavigationPluginInstance
          ]}
          renderError={renderError}
        />
      </div>
    </Worker>
  )
}

const renderPage = (props) => (
  <>
    {props.canvasLayer.children}
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          fontSize: `${2 * props.scale}rem`,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          transform: 'rotate(-45deg)',
          userSelect: 'none',
        }}
      >
        <img src={Logo} width="80" alt="Logo" className="watermark-image" />
      </div>
    </div>
    {props.annotationLayer.children}
    {props.textLayer.children}
  </>
);