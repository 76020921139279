import React, { useEffect, useState } from 'react';
import '../assets/stylyn/stylyn.css';
import { graphql, navigate } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@apollo/client';
import { getIdToken, isAuthenticated, getUserInfo } from '../utils/auth';
import { Layout, SEO } from '../components';
import ReactMarkdown from 'react-markdown';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import {
  CREATE_CHECKOUT_SESSION_MUTATION,
  GET_CONFIG_MUTATION,
} from '../graphql/mutations/documents';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '@src/store/cart';

import { PDFViewer } from 'components/PDFViewer';

const Document = ({
  data: {
    datoCmsDocument: {
      id,
      hasPassword,
      freeSample,
      freeDocument,
      fileUrl,
      description,
      name,
      price,
      title,
      keywords,
      isFeatured,
      previewUrl,
      hidePreview,
      hideOpenInNewWindow,
      fileDescription,
      description2,
      videoGuide,
      priceCode,
      seo
    },
  },
}) => {
  id = parseInt(id?.replace('DatoCmsDocument-', '').replace('-en', ''));
  priceCode = priceCode || 'price_1JYAaYIqOjsZqv7dfzW5WSQl';
  const [token] = useState(getIdToken());
  const context = {
    context: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };

  const [stripe, setStripe] = useState();
  const [getConfig] = useMutation(GET_CONFIG_MUTATION, context);
  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION_MUTATION, context);

  const user = useSelector((state) => state.user.data);
  const miniCart = useSelector((state) => new Set(state.cart.miniCart));
  const ownedProducts = useSelector((state) => new Set(state.cart.ownedProducts));
  const dispatch = useDispatch();

  useEffect(() => {
    const doFetchConfig = async () => {
      const config = await getConfig();
      setPriceCode(config.data.getConfig.priceCode);
      setStripe(await loadStripe(config.data.getConfig.publicKey));
    };

    if (isAuthenticated()) {
      doFetchConfig();
    }
  }, [getConfig]);

  const buyDocument = async (e, id) => {
    e.preventDefault();
    if (!isAuthenticated()) {
      return navigate('/account', { state: { navigatedFrom: window.location.pathname } });
    }
    const cancelUrl = window.location.pathname;
    const locale = (user && user.locale) || 'en';
    const email = (user && user.email) || '';

    const sessionData = await createCheckoutSession({
      variables: {
        cancelUrl,
        email,
        id,
        locale,
        priceCode,
      },
    });
    const result = await stripe.redirectToCheckout({
      sessionId: sessionData.data.createCheckoutSession.sessionId,
    });
    if (result.error) {
      console.log(error);
    }
  };

  function mainButtonClick(id) {
    dispatch(addToCart(id, user));
  }

  let documentState = 'Add to Cart';

  if (miniCart.has(parseInt(id))) {
    documentState = 'In cart';
  }

  if (ownedProducts.has(id.toString())) {
    documentState = 'Purchased';
  }

  return (
    <MathJaxContext>
      <Layout>
        <SEO title={seo?.title} description={seo?.description} />
        <div className="container">
          <div className="px-0.5 flex-2">
            <h1 className="content-h2">{name}</h1>
            <p className="0.75 -mt-1.5">({id})</p>
            <p className="2">£{price || '20'}</p>
            <MathJax hideUntilTypeset="first">
              {fileDescription && (
                <ReactMarkdown children={fileDescription || 'No description available.'} />
              )}
              {!fileDescription && description2 && (
                <div dangerouslySetInnerHTML={{ __html: description2 }} />
              )}
            </MathJax>
            <button
              className="button-blue w-button"
              onClick={
                documentState === 'Add to Cart'
                  ? () => {
                    mainButtonClick(id);
                  }
                  : documentState === 'Purchased'
                    ? () => {
                      navigate('/profile');
                    }
                    : () => {
                      navigate('/cart');
                    }
              }
            >
              {documentState}
            </button>
          </div>
          {!hidePreview && !!previewUrl && (
            <div className="px-0.5 pb-0.5 flex-1">
              <p className="mb-0.25">Preview</p>
              {!hideOpenInNewWindow && (
                <button
                  className="button-blue w-button mb-0.25"
                  onClick={(e) => window.open(previewUrl, '_blank')}
                >
                  View sample in new window
                </button>
              )}
              <div class="srow centered">
                <PDFViewer url={previewUrl} />
              </div>
            </div>
          )}

          {videoGuide?.url && (
            <div className="srow pb-1">
              <div className="scolumn">
                <h1 className="content-h2">Video Guide</h1>
                <div className="embedded-video-wrapper mb-1">
                  <iframe
                    src={videoGuide.url.replace('watch?v=', 'embed/')}
                    className="embedded-video"
                    title="YouTube video player"
                    frameBorder="0"
                    scrolling="no"
                    // height="100%"
                    width="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </MathJaxContext>
  );
};

export default Document;

const query = graphql`
  query DocumentQuery($id: String!) {
    datoCmsDocument(id: { eq: $id }) {
      id
      hasPassword
      freeSample
      freeDocument
      fileUrl
      hidePreview
      hideOpenInNewWindow
      description
      name
      price
      title
      keywords
      isFeatured
      previewUrl
      fileDescription
      description2
      videoGuide {
        url
        thumbnailUrl
      }
      priceCode
      seo {
        title
        description
        twitterCard
      }
    }
  }
`;
export { query };
